import React, { useState } from "react";
import { Alert } from "react-bootstrap";

import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { css } from "@emotion/react";

const MyRadioGroup = ({ label, choices, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field] = useField(props);
  return (
    <div className="row mt-1">
      <label className="font-bold">
        <input {...field} {...props} className="leading-tight" />
        <span class="ms-1 text-sm">{label}</span>
      </label>
    </div>
  );
};

const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className="row mt-1">
      <label className="col-lg-4" htmlFor={props.id || props.name}>
        {label}
      </label>
      <input className="col text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="offset-lg-4 error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const MyTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className="row mt-1">
      <label className="col-lg-4" htmlFor={props.id || props.name}>
        {label}
      </label>
      <textarea className="col text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="offset-lg-4 error">{meta.error}</div>
      ) : null}
    </div>
  );
};

// And now we can use these
const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  return (
    <>
      <Formik
        css={{}}
        initialValues={{
          subject: "Employment Opportunities",
          fullName: "",
          phoneNumber: "",
          email: "",
          message: "",
        }}
        validationSchema={Yup.object({
          fullName: Yup.string()
            .max(50, "Must be 50 characters or less")
            .required("Required"),
          phoneNumber: Yup.string()
            .max(20, "Must be 20 characters or less")
            .required("Required"),
          email: Yup.string()
            .email("Invalid email addresss`")
            .required("Required"),
          message: Yup.string().max(200, "Must be 200 characters or less"),
        })}
        onSubmit={async (values, actions) => {
          const googleFormId =
            "1FAIpQLSc2TEJk62p-veaRgxnLl39aa_4GwBR0prEYvP6QWOTch3mb6Q";
          const formBaseUrl = `https://docs.google.com/forms/d/e/${googleFormId}/formResponse`;

          let newValues = {
            "entry.2005042050": values.subject || "",
            "entry.495399485": values.fullName || "",
            "entry.632969797": values.email || "",
            "entry.2088005936": values.phoneNumber || "",
            "entry.228530338": values.message || "",
          };

          const formUrlParameters = Object.keys(newValues)
            .map((k) => `${k}=${encodeURIComponent(newValues[k])}`)
            .join("&");

          const formUrl = `${formBaseUrl}?${formUrlParameters}`;
          actions.resetForm();
          setFormSubmitted(true);

          fetch(formUrl, {
            method: "GET",
            mode: "no-cors",
          })
            .then(() => {
              actions.setSubmitting(false);
              actions.resetForm();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }}
      >
        <Form>
          <MyRadioGroup
            label="Employment Opportunities"
            name="subject"
            type="radio"
            value="Employment Opportunities"
          />
          <MyRadioGroup
            label="Business Info"
            name="subject"
            type="radio"
            value="Business Info"
          />

          <MyTextInput
            label="Name"
            name="fullName"
            type="text"
            placeholder="Your Full Name"
          />
          <MyTextInput
            label="Phone Number"
            name="phoneNumber"
            type="text"
            placeholder="Your Phone Number"
          />
          <MyTextInput
            label="Email Address"
            name="email"
            type="email"
            placeholder="email@your-domain.com"
          />
          <MyTextArea
            label="Message"
            name="message"
            placeholder="Your Message . . ."
          />

          {formSubmitted ? (
            <Alert className="mt-4" variant="dark">
              Your message has been sent to info@rubyrm.com
            </Alert>
          ) : null}
          <button
            css={css`
              background-color: #951219;
              border-color: #951219;
              color: white;
              font-weight: 600;
            `}
            className="mt-3"
            type="submit"
          >
            Submit
          </button>
        </Form>
      </Formik>
    </>
  );
};

export default ContactForm;
