import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ContactForm from "../components/contactform";
import { css } from "@emotion/react";

const IndexPage = () => (
  <Layout>
    <Seo
      title="Home"
      keywords={[
        `Utilization`,
        `Pathway`,
        `Health`,
        `Information`,
        `Clinical`,
        `Review`,
      ]}
    />
    <Row className="g-0">
      <div style={{ display: "grid" }}>
        <StaticImage
          style={{
            gridArea: "1/1",
          }}
          src="../images/shutterstock_1050199505.jpg"
          alt="main image"
          placeholder="blurred"
          layout="constrained"
          width={1920}
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <StaticImage
            src="../images/RRMI_horizontal_logo.png"
            layout="constrained"
            style={{ maxWidth: "75vw" }}
          />
        </div>
      </div>
    </Row>
    <Row className="g-0">
      <Col
        className="m-4 mb-0"
        md="6"
        css={css`
          max-width: 95%;
          & > h1 {
            color: #951219;
          }
        `}
      >
        <h1>WHO WE ARE</h1>
        <p>
          <ul>
            <li>
              Maximize the benefits of EMR through efficient and quality
              clinical record review
            </li>
            <li>Elevate the quality of clinical record audit/review</li>
            <li>
              Increase efficiencies involving Health Information Management
            </li>
            <li>
              Establish consistency in clinical documentation to meet skilled
              nursing documentation requirements and optimize revenue
              opportunity
            </li>
            <li>
              Assist in retrieving Health Information Records and relevant
              documents necessary to satisfy Medicare, Medi-Cal, Managed Care
              and other Payer’s request for documentation
            </li>
          </ul>
        </p>
        <h1>WHAT WE DO</h1>
        <p>
          Utilization Review Clinical Pathway and Health Information Clinical
          Review
        </p>
      </Col>
      <Col
        className="m-4"
        css={css`
          & > h1 {
            color: #951219;
          }
        `}
      >
        <h1>GET IN TOUCH</h1>
        <p>Please let us know if you have any questions.</p>

        <Container>
          <ContactForm />
        </Container>
      </Col>
    </Row>
  </Layout>
);

export default IndexPage;
